<template>
    <div class="header">
        <!---Logo part -->
        <router-link :to="{ name: 'Dashboard' }" class="header__logo">
            <img src="@/assets/images/runshop-logo.svg" />
        </router-link>
        <div class="header__instance-select">
            <instancesSelect></instancesSelect>
        </div>
        <div class="header__search">
            <header-search></header-search>
        </div>
        <!---User -->
        <div class="header__menu-container">
            <Menu fixed>
                <template v-slot:activator="{ on }">
                    <div class="popup-trigger" v-on="on">
                        <Button type="large-text">
                            {{ currentUser.email }}
                            <span class="icon popup-trigger__icon">expand_more</span>
                        </Button>
                    </div>
                </template>
                <div class="header__menu list">
                    <!---User info -->
                    <!-- <v-list-item>
                    <v-list-item-avatar class="user__avatar">
                        <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="John" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="user__name">{{ userName }}</v-list-item-title>
                        <v-list-item-title>{{ userEmail }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mb-3 mt-4"></v-divider> -->
                    <!---End User info -->
                    <!-- <v-list-item v-for="(item, i) in userprofile" :key="i" :to="item.to" @click="href" color="primary">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item> -->
                    <div class="list__item" @click="logout()">
                        <div>{{ $t('menus.user.logout') }}</div>
                    </div>
                </div>
            </Menu>
        </div>
        <div class="header__lang">
            <a @click="changeLocale('ru')" class="language-button">
                <img class="language-button__image" src="@/assets/images/flags/ru.svg" />
            </a>
            <a @click="changeLocale('en')" class="language-button">
                <img class="language-button__image" src="@/assets/images/flags/gb.svg" />
            </a>
        </div>
    </div>
</template>
<script>
// Utilities
import { mapState, mapGetters, mapMutations } from 'vuex';
import Button from '@/components/common/Button';
import { accountUrl } from '@/helpers/values';
import HeaderSearch from '@/components/search/headerSearch/HeaderSearch';
import { reset } from '@/api/auth';
import Menu from '@/components/common/Menu';
import instancesSelect from '@/components/user/instancesSelect.vue';

export default {
    name: 'VerticalHeader',

    components: {
        Menu,
        HeaderSearch,
        Button,
        instancesSelect,
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showLogo: true,
        fav: true,
        href() {
            return undefined;
        },
        searchString: '',
        searchItems: [],
    }),

    computed: {
        ...mapState(['navbarColor', 'Sidebar_drawer']),
        ...mapGetters('globalVars', {
            currentUser: 'getCurrentUser',
        }),
        userEmail() {
            // return this.$store.state.auth.email;
            return 'example@mail.com';
        },
        userName() {
            //return this.$store.state.auth.email;
            return 'testName';
        },
        userprofile() {
            return [
                {
                    title: this.$t('menus.user.account'),
                    to: {
                        modal: true,
                        name: 'Edit User',
                        params: {
                            id: localStorage.getItem('rs-id'),
                        },
                    },
                },
            ];
        },
    },

    methods: {
        ...mapMutations({
            setSidebarDrawer: 'SET_SIDEBAR_DRAWER',
        }),
        showhideLogo: function() {
            this.showLogo = !this.showLogo;
        },

        changeLocale(locale) {
            this.$i18n.locale = locale;
            localStorage.setItem('locale', locale);
        },

        async logout() {
            await reset();
            location.replace(`${accountUrl}/auth/logout`);
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.header {
    background-color: var(--v-surface-base) !important;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 64px;
    padding: 14px 30px 14px 20px;
    margin-top: 0;
    transform: translateY(0px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    z-index: 7;
    gap: 30px;

    &__logo {
        width: 200px;

        img {
            display: block;
            max-width: 100%;
        }
    }
    &__instance-select {
    }
    &__search {
        flex: 1;
        max-width: 620px;
        margin: auto;
    }
    &__menu-container {
    }
    &__lang {
        display: flex;
        gap: 10px;
    }
}
.header__menu {
    padding: 32px 0;
    width: 167px;
    & > .v-list-item {
        padding: 0 32px;
        &:first-child {
            padding: 12px 16px;
        }
    }
}

.language-button {
    &__image {
        width: 20px;
        height: 20px;
    }
}

.popup-trigger {
    span {
        display: flex;
        align-items: center;
        font-size: 1.1429rem;
        font-weight: 400;
    }

    &__icon {
        font-size: 22px !important;
        margin-left: 4px;
    }
}
</style>
